import * as React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import App from '../components/layout/App';
import Content from '../components/base/Content';
import ContentCenter from '../components/layout/ContentCenter';
import {
  mqDown,
  breakpoint,
  fontWeight,
  lineHeight,
  fontSize,
} from '../constants/styles';

interface AtelierProps {
  rest: any;
  data: {
    content: {
      edges: [
        {
          node: {
            data: {
              seo_title: string;
              content: {
                html: string;
              };
              team: [
                {
                  name: string;
                  text: {
                    html: string;
                  };
                  img: {
                    alt: string;
                    localFile: any;
                  };
                },
              ];
            };
          };
        },
      ];
    };
  };
}

const MemberName = styled.h2`
  font-weight: ${fontWeight.regular};
  line-height: ${lineHeight.L};
  font-size: ${fontSize.XL};
  margin-bottom: 1.5rem;
`;

const Member = styled.div`
  margin-top: 6rem;
  ${mqDown(breakpoint.tablet)} {
    text-align: center;
  }
`;

const Office: React.SFC<AtelierProps> = ({ data, ...rest }) => {
  const DATA = data.content.edges[0].node.data;
  const createContentMarkup = () => {
    return { __html: DATA.content.html };
  };
  return (
    <App title={DATA.seo_title} {...rest}>
      <ContentCenter>
        <div>
          <Content dangerouslySetInnerHTML={createContentMarkup()} />
          {DATA.team.map((member, index) => (
            <Member key={`member-${index}`}>
              <MemberName>{member.name}</MemberName>
              <Content dangerouslySetInnerHTML={{ __html: member.text.html }} />
            </Member>
          ))}
        </div>
      </ContentCenter>
    </App>
  );
};
export default Office;

export const pageQuery = graphql`
  query AtelierQuery {
    content: allPrismicAtelier {
      edges {
        node {
          data {
            seo_title
            content {
              html
            }
            team {
              name
              text {
                html
              }
            }
          }
        }
      }
    }
  }
`;
